import { render, staticRenderFns } from "./permission-denied.vue?vue&type=template&id=34256e2f&"
import script from "./permission-denied.vue?vue&type=script&lang=js&"
export * from "./permission-denied.vue?vue&type=script&lang=js&"
import style0 from "./permission-denied.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/frank/Documents/RacknerProjects/Bobtail/bobtail_reset_6-3/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('34256e2f')) {
      api.createRecord('34256e2f', component.options)
    } else {
      api.reload('34256e2f', component.options)
    }
    module.hot.accept("./permission-denied.vue?vue&type=template&id=34256e2f&", function () {
      api.rerender('34256e2f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/permission-denied.vue"
export default component.exports