var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "PermissionDenied" },
    [
      _c("h1", { staticClass: "PermissionDenied__title fs-18" }, [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n  ")
      ]),
      _vm.reason
        ? _c("h2", { staticClass: "PermissionDenied__reason fs-14" }, [
            _vm._v("\n    " + _vm._s(_vm.reason) + "\n  ")
          ])
        : _vm._e(),
      _c(
        "base-button",
        { staticClass: "bg-blue fc-white mt-10", on: { click: _vm.goBack } },
        [_vm._v("\n    Go Back\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }