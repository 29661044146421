<template>
  <div class="PermissionDenied">
    <h1 class="PermissionDenied__title fs-18">
      {{ title }}
    </h1>
    <h2
      v-if="reason"
      class="PermissionDenied__reason fs-14"
    >
      {{ reason }}
    </h2>
    <base-button
      @click="goBack"
      class="bg-blue fc-white mt-10"
    >
      Go Back
    </base-button>
  </div>
</template>

<script>
// Components
import BaseButton from '../components/base-button.vue'

export default {
  name: 'PermissionDenied',

  components: {
    BaseButton,
  },

  props: {
    reason: {
      type: String,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: 'Permission Denied',
    },
  },

  methods: {
    goBack () {
      this.$router.go(-2)
    }
  },
}
</script>

<style lang="sass">
.PermissionDenied
  padding: 1rem

  &__reason

  &__title

</style>